import dynamic from 'next/dynamic';

// Dynamic imports
const Icon = dynamic(() => import('../../../components/Icons/Icon'));

const SocialMenu = () => {
  const s = require('./SocialMenu.module.scss');
  return (
    <div className={s.SocialMenu}>
      <ul>
        <li className={s.MenuItem}>
          <a
            aria-label="Vodacom Facebook link"
            href="https://www.facebook.com/Vodacom"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="SOCIAL_FACEBOOK" theme="facebook" size={40} />
          </a>
        </li>
        <li className={s.MenuItem}>
          <a
            aria-label="Vodacom YouTube link"
            href="https://www.youtube.com/user/VODACOMTV"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="SOCIAL_YOUTUBE" theme="youtube" size={40} />
          </a>
        </li>
        <li className={s.MenuItem}>
          <a
            aria-label="Vodacom Twitter link"
            href="https://twitter.com/vodacom"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="SOCIAL_TWITTER" theme="twitter" size={40} />
          </a>
        </li>
        <li className={s.MenuItem}>
          <a
            aria-label="Vodacom LinkedIn link"
            href="http://www.linkedin.com/company/vodacom"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="SOCIAL_LINKEDIN" theme="linkedin" size={40} />
          </a>
        </li>
        <li className={s.MenuItem}>
          <a
            aria-label="Vodacom website link"
            href="http://www.vodacom.co.za"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="SOCIAL_VODACOM" theme="vodacom" size={40} />
          </a>
        </li>
      </ul>
    </div>
  );
};
export default SocialMenu;
